import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from '../signup/theme/getSignUpTheme';
import SitemarkIcon from '../components/SitemarkIcon';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from '../providers/ThemeContextProvider';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const LogoutContainer = styled(Stack)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
        backgroundImage:
            'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
}));

export default function Logout() {
    const { mode, toggleTheme } = useThemeContext();
    const [showCustomTheme, setShowCustomTheme] = React.useState(true);
    const defaultTheme = createTheme({ palette: { mode } });
    const LogoutTheme = createTheme(getSignUpTheme(mode));
    const navigate = useNavigate();

    
    const handleLogout = () => {
        // Perform logout logic (clearing auth tokens, etc.)
        localStorage.removeItem('authToken');
        navigate('/'); // Redirect to login page
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LogoutTheme : defaultTheme}>
            <CssBaseline enableColorScheme />
            <LogoutContainer direction="column" justifyContent="space-between">
                <Card variant="outlined">
                    <SitemarkIcon />
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                    >
                        Logout
                    </Typography>
                    <Typography sx={{ textAlign: 'left' }}>
                        Are you sure you want to logout?
                    </Typography>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleLogout}
                            color="primary"
                        >
                            Logout
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => navigate('/home')} // Adjust route as needed
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Divider>
                        <Typography sx={{ color: 'text.secondary' }}>or</Typography>
                    </Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => navigate('/')}
                        >
                            Back to Home
                        </Button>
                    </Box>
                </Card>
            </LogoutContainer>
        </ThemeProvider>
    );
}
