import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import Slider from 'react-slick';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
import { Button, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BuildIcon from '@mui/icons-material/Build';
import CategoryIcon from '@mui/icons-material/Category';
import { useFurniture } from '../providers/FurnitureProvider';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


interface Author {
  name: string;
  avatar: string;
}

interface PurchaseDate {
  month: string;
  year: number;
}

interface Furniture {
  id: number
  images: string[];
  model_name: string;
  materials: string[];
  brand: string;
  added_by: string;
  purchaseDate: PurchaseDate;
  yearsOfOwnership: number;
  how_used: string;
  description: string;
  priceOfPurchase: string;
  purchasedFrom: string;
  price: string;
  firstOwner: boolean;

  predicted_price: string;
  predicted_price_chart: string;
  predicted_price_reasoning: string;
}

const SyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
  },
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

function Author({ authors }: { authors: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
      >
        <Typography variant="caption">
          Added By {authors}
        </Typography>
      </Box>
      <Typography variant="caption">July 14, 2021</Typography>
    </Box>
  );
}

export function Search() {
  return (
    <FormControl sx={{ width: { xs: '100%', md: '25ch' } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      />
    </FormControl>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const domain = 'https://furniture.cyferwall.com';


export default function MainContent() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState<number | null>(
    null,
  );

  const { furnitureList } = useFurniture();

  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    navigate(`/furniture/${id}`);
  };


  const handleFocus = (index: number) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info('You clicked the filter chip.');
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <div>
          <Typography variant="h2" gutterBottom>
            Price Your Product
          </Typography>
          <Typography>
            Not sure of the price of your piece of furniture? Use our proprietary algorithm to get an estimate based on market data.
          </Typography>
        </div>

        {/* Horizontal Form Fields */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'flex-start',
            flexWrap: 'wrap', // allows wrapping on smaller screens
          }}
        >
          {/* Image Upload Field */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Typography variant="subtitle2" gutterBottom>Upload Images</Typography>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onClick={() => document.location.href = '/add-a-product'}
            >
              Upload Files
              <VisuallyHiddenInput
                type="file"
                // Redirect to add-a-product
                onChange={(event) => document.location.href = '/add-a-product'}
                multiple
              />
            </Button>
          </Box>

          {/* Brand and Model Field */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" gutterBottom>Brand & Model Name</Typography>
            <TextField
              placeholder="e.g., IKEA LACK Table"
              variant="outlined"
              fullWidth
              onClick={() => document.location.href = '/add-a-product'}
            />
          </Box>

          {/* Description Field */}
          <Box sx={{ flex: 2 }}>
            <Typography variant="subtitle2" gutterBottom>Description</Typography>
            <TextField
              placeholder="Provide a description of the product"
              variant="outlined"
              multiline
              rows={1} // Adjust for one-line appearance
              fullWidth
              onClick={() => document.location.href = '/add-a-product'}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <div>
          <Typography variant="h1" gutterBottom>
            Products
          </Typography>
          <Typography>Find Products listed by our loyal customers at market prices, predicted by our proprietary algorithm.</Typography>
        </div>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: 'row',
            gap: 1,
            width: { xs: '100%', md: 'fit-content' },
            overflow: 'auto',
          }}
        >
          <Search />
          {/* <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: '100%',
            justifyContent: 'space-between',
            alignItems: { xs: 'start', md: 'center' },
            gap: 4,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: 'row',
              gap: 3,
              overflow: 'auto',
            }}
          >
            {/* <Chip onClick={handleClick} size="medium" label="All categories" />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Company"
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
            />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Product"
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
            />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Design"
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
            />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Engineering"
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
            /> */}
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'row',
              gap: 1,
              width: { xs: '100%', md: 'fit-content' },
              overflow: 'auto',
            }}
          >
            

          </Box>
        </Box>
        <Grid container spacing={2} columns={12}>
          {furnitureList.map((card: Furniture, index: number) => (
            <Grid key={index} size={{ xs: 12, md: 6 }}>
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(index)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === index ? 'Mui-focused' : ''}
                onClick={() => handleCardClick(card.id)} // Navigate on click
              >
                {/* Image Carousel */}
                <Slider {...settings}>
                  {card.images.map((imgPath:string, imgIndex:number) => (
                    <Box key={imgIndex}>
                      <img
                        src={`${domain}${imgPath}`}
                        alt={card.model_name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderColor: 'divider',
                          aspectRatio: '16 / 9',
                        }}
                      />
                    </Box>
                  ))}
                </Slider>

                <SyledCardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {card.model_name}
                  </Typography>
                  <StyledTypography variant="body2" color="text.secondary" gutterBottom>
                    {card.description}
                  </StyledTypography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      paddingTop: 1,
                    }}
                  >
                    <Chip
                      icon={<MonetizationOnIcon />}
                      size="small"
                      label={`$${card.predicted_price}`}
                      sx={{
                        backgroundColor: '#E0F7FA',
                        color: '#00796B',
                        fontWeight: 'bold',
                      }}
                    />
                    <Chip
                      icon={<BuildIcon />}
                      size="small"
                      label={card.how_used}
                      sx={{
                        backgroundColor: '#FFEBEE',
                        color: '#D32F2F',
                        fontWeight: 'bold',
                      }}
                    />
                    <Chip
                      icon={<CategoryIcon />}
                      size="small"
                      label={card.materials}
                      sx={{
                        backgroundColor: '#E8F5E9',
                        color: '#388E3C',
                        fontWeight: 'bold',
                      }}
                    />
                  </Stack>
                </SyledCardContent>
                <Author authors={card.added_by} />
              </SyledCard>
            </Grid>
          ))}

          {furnitureList.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
            >
                <Typography variant="caption">
                  No products found. Add products to view them here.
                </Typography>
              </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}
