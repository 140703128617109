import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('authToken') || null);
    const [loading, setLoading] = useState(false);

    // Fetch user data if token exists
    useEffect(() => {
        // Fetch token
        const token = localStorage.getItem('authToken');
        console.log(`Token = ${token}`);
        if (token) {
            setLoading(true);
            axios
                .get('/backend/auth', {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setUser(response.data);
                })
                .catch(() => {
                    // logout();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [token]);

    // Function to handle login
    const login = async (email, password) => {
        setLoading(true);
        try {
            console.log(email, password);
            const response = await axios.post('https://furniture.cyferwall.com/backend/auth/login/', { email, password });
            // If the response code is 200 then set the token, which is returned as key in the response
            if (response.status === 200) {
                console.log(response.data);
                const { key } = response.data;
                setToken(key);
                localStorage.setItem('authToken', key);
            } else {
                console.error('Login failed:', response.data);
                alert('Login failed. Please check your credentials. Response code: ' + response.data);
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed. Please check your credentials. Error: ' + error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle sign up
    const signup = async (email, password, name) => {
        setLoading(true);
        try {
            console.log(email, password, name);
            const response = await axios.post('https://furniture.cyferwall.com/backend/register/', { username: name, email: email, password1: password, password2: password });
            console.log(response.status, response.data);
            if (response.status === 204) {
                console.log("Registration Success");
                await login(email, password);
            } else {
                console.error('Signup failed:', response.data);
                alert('Signup failed. Please try again. Response code: ' + response.status + " " + response.data);
            }
        } catch (error) {
            console.error('Signup failed:', error);
            alert('Signup call failed. Please try again. Error: ' + error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle logout
    const logout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('authToken');
    };

    return (
        <AuthContext.Provider
            value={{ user, token, loading, login, logout, signup }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
