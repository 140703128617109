import React, { useState } from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';

const ImageUpload = ({ onUpload }) => {
    const [images, setImages] = useState([]);

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        // only allow jpeg, png and jpg files
        const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg'];
        const invalidFiles = selectedFiles.filter((file) => !allowedExtensions.includes(file.type));
        if (invalidFiles.length > 0) {
            alert('Please select only .jpg, .png, or .jpeg files.');
            return; 
        }

        const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
        setImages((prev) => [...prev, ...newImages]);
        onUpload(selectedFiles); // Pass the file objects to the parent component
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        const newImages = droppedFiles.map((file) => URL.createObjectURL(file));
        setImages((prev) => [...prev, ...newImages]);
        onUpload(droppedFiles); // Pass the file objects to the parent component
    };

    const handleRemoveImage = (index) => {
        setImages((prev) => {
            const newImages = prev.filter((_, i) => i !== index);
            return newImages;
        });
    };

    return (
        <Box
            sx={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                padding: '16px',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'border-color 0.3s ease',
                '&:hover': {
                    borderColor: '#aaa',
                },
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="image-upload-input"
            />
            <label htmlFor="image-upload-input">
                <Button variant="contained" component="span">
                    Upload Images
                </Button>
            </label>
            <Typography variant="body2" sx={{ mt: 2 }}>
                Drag and drop images here or click to select
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {images.map((image, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                        <Box
                            sx={{
                                position: 'relative',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={image}
                                alt={`Uploaded ${index}`}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                }}
                            />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleRemoveImage(index)}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    zIndex: 1,
                                }}
                            >
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ImageUpload;
