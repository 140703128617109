import React, { createContext, useContext, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

const ThemeContextProvider = ({ children }) => {
    const [mode, setMode] = useState(null); // Set initial state to null to signify loading
    const [loading, setLoading] = useState(true);

    // Load theme preference from localStorage or system preference
    useEffect(() => {
        const savedMode = localStorage.getItem('themeMode');
        if (savedMode) {
            setMode(savedMode);
        } else {
            const systemPrefersDark = window.matchMedia(
                '(prefers-color-scheme: dark)',
            ).matches;
            setMode(systemPrefersDark ? 'dark' : 'light');
        }
        setLoading(false); // Finished loading
    }, []);

    const toggleTheme = () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('themeMode', newMode);
    };

    const theme = createTheme({
        palette: {
            mode: mode || 'light', // Default to light if mode is still null
            ...(mode === 'dark' && {
                background: {
                    default: '#121212',
                    paper: '#1c1c1c',
                },
                text: {
                    primary: '#ffffff',
                },
            }),
            ...(mode === 'light' && {
                background: {
                    default: '#ffffff',
                    paper: '#f5f5f5',
                },
                text: {
                    primary: '#000000',
                },
            }),
        },
    });

    if (loading) {
        return null; // Don't render children until theme is loaded
    }

    return (
        <ThemeContext.Provider value={{ mode, toggleTheme }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
