import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider'; // Import the AuthProvider to get the token




// Create a context for the furniture data
const FurnitureContext = createContext();

export const useFurniture = () => useContext(FurnitureContext);

const FurnitureProvider = ({ children }) => {
    const { token } = useAuth(); // Get the token from AuthProvider
    const [furnitureList, setFurnitureList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFurniture, setSelectedFurniture] = useState(null);

    // Function to fetch all furniture
    const loadFurniture = async () => {
        if (!token) {
            setError('User is not logged in.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get('https://furniture.cyferwall.com/backend/furniture/', {
                headers: {
                    Authorization: `Token ${token}`, // Only the Authorization header; do not set Content-Type manually
                },
            }
            );

            // if the response is 200
            if (response.status === 200) {
                console.log("Successfully fetched furniture");
                setFurnitureList(response.data);
            } else {
                console.error('Failed to fetch furniture:', response.data);
                setError('Failed to fetch furniture. Please try again.');
            }
        } catch (err) {
            console.error('Failed to load furniture:', err);
            setError('Failed to load furniture.');
        } finally {
            setLoading(false);
        }
    };

    const addFurniture = async (formData) => {
        try {
            const data = new FormData();

            console.log("addFurniture formData", formData);

            // Change format of purchase_date and year_of_launch from Wed, 16 Oct 2024 18:30:00 GMT to YYYY-MM-DD
            console.log("addFurniture formData.purchaseDate", formData.purchaseDate);
            const purchaseDate = formData.purchaseDate.format('YYYY-MM-DD');
            console.log("addFurniture formData.purchaseDate", purchaseDate);
            console.log("addFurniture formData.launchDate", formData.launchDate);
            const launchDate = formData.launchDate.format('YYYY-MM-DD')
            console.log("addFurniture formData.launchDate", launchDate);

            var predictionYears = parseInt(formData.predictionYears[0]);

            // Add form fields to FormData
            data.append('model_name', formData.modelName);
            data.append('materials', formData.material);
            data.append('brand_name', formData.brandName);
            data.append('purchase_date', purchaseDate);
            data.append('year_of_launch', launchDate);
            data.append('how_used', formData.usage);
            data.append('description', formData.description);
            data.append('price_of_purchase', parseInt(formData.price));
            data.append('original_price', parseInt(formData.originalPrice));
            data.append('purchased_from', formData.purchasedFrom);
            data.append('first_owner', formData.isFirstOwner === 'yes');
            data.append("prediction_years", predictionYears);

            // Append images to FormData
            formData.images.forEach((image, index) => {
                data.append(`images`, image, image.name || `image${index}.jpg`);
            });

            // print formdata
            console.log(data);

            const response = await fetch('https://furniture.cyferwall.com/backend/furniture/', {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`, // Only the Authorization header; do not set Content-Type manually
                },
                body: data, // Use the FormData as the request body
            });

            if (response.status === 201) {
                const responseData = await response.json();
                return responseData; // The response data should include the ID for redirection
            } else {
                throw new Error(`Failed to add furniture. Status: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    };



    // Function to fetch details of a specific furniture by ID
    const fetchFurnitureById = async (furnitureId) => {
        if (!token) {
            setError('User is not logged in.');
            return;
        }

        setLoading(true);
        try {
            console.log("Loading furniture with id " + furnitureId);
            // access from furnitureList
            for (let i = 0; i < furnitureList.length; i++) {
                // convert to inr then compare
                if (furnitureList[i].id === furnitureId) {
                    console.log("furnitureList[i]", furnitureList[i]);
                    setSelectedFurniture(furnitureList[i]);
                    break;
                }
            }

            console.log("furnitureList does not contain id " + furnitureId);

        } catch (err) {
            console.error('Failed to fetch furniture details:', err);
            setError('Failed to fetch furniture details.');
        } finally {
            setLoading(false);
        }
    };

    // Run load furniture when the component mounts
    useEffect(() => {
        loadFurniture();
        // eslint-disable-next-line
        return () => {
            // Cleanup when the component unmounts
        };
    }, []);

    // Provide furniture-related functions and data to children components
    return (
        <FurnitureContext.Provider
            value={{
                furnitureList,
                loading,
                error,
                selectedFurniture,
                loadFurniture,
                addFurniture,
                fetchFurnitureById,
            }}
        >
            {children}
        </FurnitureContext.Provider>
    );
};

export default FurnitureProvider;
