import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, PaletteMode } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import MainContent from './components/MainContent';
import Latest from './components/Latest';
import Footer from './components/Footer';
import TemplateFrame from './TemplateFrame';

import getBlogTheme from './theme/getBlogTheme';
import { useThemeContext } from './providers/ThemeContextProvider';



export default function Blog() {

  const { mode, toggleTheme } = useThemeContext();

  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const blogTheme = createTheme(getBlogTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
 
  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    toggleTheme();
  };

 
  return (
    // <TemplateFrame
    //   toggleCustomTheme={toggleCustomTheme}
    //   showCustomTheme={showCustomTheme}
    //   mode={mode}
    //   toggleColorMode={toggleColorMode}
    // >
    <ThemeProvider theme={showCustomTheme ? blogTheme : defaultTheme}>
      <CssBaseline enableColorScheme />

      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {/* ADD SOME PADDING */}
      <div style={{ height: '4rem' }} />
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4 }}
      >
        <MainContent />
        {/* <Latest /> */}
      </Container>
      <Footer />
    </ThemeProvider>
    // </TemplateFrame>
  );
}
