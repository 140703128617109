import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import getBlogTheme from './theme/getBlogTheme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Blog from './Blog';
import SignIn from './sign_in/SignIn';
import SignUp from './signup/SignUp';
import Logout from './logout/LogOut';
import ThemeContextProvider from './providers/ThemeContextProvider';
import AuthProvider from './providers/AuthProvider';
import ProtectedRoute from './components/protectedRoute';
import AddAProduct from './add_a_product';
import Profile from './profile';
import PrivacyPolicy from './privacy_policy';
import TermsOfService from './terms_of_service';
import FurnitureProvider from './providers/FurnitureProvider';
import FurnitureDetails from "./product_details.jsx"

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Blog />
        ),
    },
    {
        path: "/sign-in",
        element: <SignIn />
    },
    {
        path: "/sign-up",
        element: <SignUp />
    },
    {
        path: "/logout",
        element: (
            <ProtectedRoute>
                <Logout />
            </ProtectedRoute>
        )
    },
    {
        path: "/add-a-product",
        element: (
            <ProtectedRoute>
                <AddAProduct />
            </ProtectedRoute>
        )
    },
    {
        path: "/furniture/:id",
            
        element: <ProtectedRoute><FurnitureDetails /> </ProtectedRoute >
    },
    {
        path: "/profile",
        element: (
            <ProtectedRoute>
                <Profile />
            </ProtectedRoute>
        )
    },
    {
        path: "/terms-of-service",
        element: (
            <TermsOfService />
        )
    },
    {
        path: "/privacy-policy",
        element: (
            <PrivacyPolicy />
        )
    },


]);

root.render(
        <ThemeContextProvider>
            <AuthProvider>
                <FurnitureProvider>
                    <RouterProvider router={router} />
                </FurnitureProvider>
            </AuthProvider>
        </ThemeContextProvider>
);
