import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import { useThemeContext } from './providers/ThemeContextProvider';
import getBlogTheme from './theme/getBlogTheme';

export default function TermsOfService() {
    const { mode, toggleTheme } = useThemeContext();
    const [showCustomTheme, setShowCustomTheme] = React.useState(true);
    const blogTheme = createTheme(getBlogTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });

    const toggleColorMode = () => {
        toggleTheme();
    };

    const toggleCustomTheme = () => {
        setShowCustomTheme((prev) => !prev);
    };

    return (
        <ThemeProvider theme={showCustomTheme ? blogTheme : defaultTheme}>
            <CssBaseline enableColorScheme />
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Box sx={{ height: '4rem' }} />
            <Container
                maxWidth="lg"
                component="main"
                sx={{ display: 'flex', flexDirection: 'column', my: 4, gap: 4, mt:20 }}
            >
                <Typography variant="h2"  gutterBottom>
                    Terms of Service
                </Typography>

                <Typography variant="body1" paragraph>
                    Welcome to [Your Company]. By accessing our services, you agree to be bound by these Terms of Service. Please read them carefully.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    By using our services, you confirm that you accept these terms and agree to comply with them. If you do not agree to these terms, please do not use our services.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    2. Changes to the Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting. Your continued use of our services constitutes acceptance of the new terms.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    3. Use of Services
                </Typography>
                <Typography variant="body1" paragraph>
                    You agree to use our services only for lawful purposes. You are responsible for your own communications and any consequences that arise from them.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    4. Intellectual Property
                </Typography>
                <Typography variant="body1" paragraph>
                    All content provided on our services, including text, graphics, logos, and software, is the property of [Your Company] or our licensors. You may not use, reproduce, or distribute any of this content without our permission.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    5. Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    To the fullest extent permitted by law, [Your Company] shall not be liable for any damages resulting from your use of our services, including, but not limited to, indirect, incidental, or consequential damages.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    6. Governing Law
                </Typography>
                <Typography variant="body1" paragraph>
                    These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                </Typography>

                <Typography  variant="h4" gutterBottom>
                    7. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about these Terms of Service, please contact us at [Your Contact Information].
                </Typography>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
