import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, PaletteMode } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import { useThemeContext } from './providers/ThemeContextProvider';
import getBlogTheme from './theme/getBlogTheme';

export default function PrivacyPolicy() {
    const { mode, toggleTheme } = useThemeContext();
    const [showCustomTheme, setShowCustomTheme] = React.useState(true);
    const blogTheme = createTheme(getBlogTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });

    const toggleColorMode = () => {
        toggleTheme();
    };

    const toggleCustomTheme = () => {
        setShowCustomTheme((prev) => !prev);
    };

    return (
        <ThemeProvider theme={showCustomTheme ? blogTheme : defaultTheme}>
            <CssBaseline enableColorScheme />
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Box sx={{ height: '4rem' }} />
            <Container
                maxWidth="lg"
                component="main"
                sx={{ display: 'flex', flexDirection: 'column', my: 4, gap: 4, mt: 15 }}
            >
                <Typography variant="h3" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    At [Your Company], we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    1. Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We may collect the following information:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">Personal identification information (Name, email address, phone number, etc.)</Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Usage data (IP address, browser type, and version, pages you visit, time and date of your visit, etc.)</Typography>
                    </li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    2. How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use the information we collect to:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">Provide, operate, and maintain our services</Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Improve and personalize your experience</Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Communicate with you, either directly or through one of our partners</Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Develop new products, services, features, and functionality</Typography>
                    </li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    3. Sharing Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not sell or rent your personal information to third parties. We may share your information with:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">Service providers who help us operate our services</Typography>
                    </li>
                    <li>
                        <Typography variant="body1">Law enforcement or regulatory agencies if required by law</Typography>
                    </li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    4. Security of Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement a variety of security measures to ensure the safety of your personal information. However, no method of transmission over the internet is completely secure, and we cannot guarantee its absolute security.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    5. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time. We encourage you to review this Privacy Policy periodically for any changes.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    6. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about this Privacy Policy, please contact us at [Your Contact Information].
                </Typography>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
