import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

// Sample random messages
const randomMessages = [
    "Gathering your product details...",
    "Please hold on, we are processing your request...",
    "Just a moment, we are adding your product...",
    "Fetching the best options for you...",
    "Almost there, please wait a bit...",
    "Loading... we promise it's worth it!",
    "Thank you for your patience...",
    "Hang tight, we are on it!"
];

export default function LoadingScreen() {
    const [message, setMessage] = React.useState("");

    // Function to randomly select a message
    const getRandomMessage = () => {
        const randomIndex = Math.floor(Math.random() * randomMessages.length);
        setMessage(randomMessages[randomIndex]);
    };

    // Change the message every few seconds
    React.useEffect(() => {
        getRandomMessage();
        const intervalId = setInterval(getRandomMessage, 4000); // Change message every 4 seconds

        // Cleanup interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" sx={{ backgroundColor: '#f5f5f5', padding: 3, borderRadius: 2, boxShadow: 3 }}>
            <CircularProgress size={60} sx={{ marginBottom: 2 }} />
            <Typography variant="h6" component="div" color="textSecondary">
                {message}
            </Typography>
            {/* add a please don't leave or refresh this page message */}
            <Typography variant="subtitle2" component="div" color="textSecondary">
                Please don't leave or refresh this page.
            </Typography>
        </Box>
    );
}
