import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();

    if (token == null) {
        // Redirect to the login page if not authenticated
        return <Navigate to="/sign-in" />;
    }

    return children;
};

export default ProtectedRoute;
