import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, TextField, Button, MenuItem, Grid, Typography, FormControl, InputLabel, Select } from '@mui/material';

import { useThemeContext } from './providers/ThemeContextProvider';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import ImageUpload from './components/ImageUpload';
import getBlogTheme from './theme/getBlogTheme';
import LoadingScreen from './components/loading_screen';
import { useFurniture } from './providers/FurnitureProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const materials = ["Leather", "Fabric", "Wood", "Metal", "Plastic"];
const usageTypes = ["Heavy Use", "Rare Use", "Mint Condition"];
const brands = ["Heavy Use", "Rare Use", "Mint Condition"];
const ownerOptions = ["Second Owner", "Third Owner", "Fourth Owner", "I'm not sure"];
const predictionOptions = ["Current", "1 Year", "2 Years", "3 Years"];
const labelStyle = {
    transform: "translate(0, 1.5px) scale(1)", // Adjust position for the focused state
    transition: "all 0.2s ease-in-out", // Smooth transition
};

const floatingLabelStyle = {
    transform: "translate(0, -35px) scale(0.50)", // Move up when focused
};
export default function AddAProduct() {
    const { addFurniture } = useFurniture();
    const { mode, toggleTheme } = useThemeContext();
    const [formData, setFormData] = React.useState({
        images: [],
        modelName: '',
        material: '',
        brandName: '',
        purchaseDate: null,
        launchDate: null,
        usage: '',
        description: '',
        price: '',
        originalPrice: '',
        purchasedFrom: '',
        isFirstOwner: 'no',
        numberOfOwners: '',
        predictionYears: '',
    });
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);



    const handlePurchaseDateChange = (date) => {
        setFormData((prev) => ({ ...prev, purchaseDate: date }));
    };

    const handleLaunchDateChange = (date) => {
        setFormData((prev) => ({ ...prev, launchDate: date }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if (name === 'isFirstOwner' && value === 'yes') {
            setFormData((prev) => ({ ...prev, numberOfOwners: '' }));
        }
    };

    const handleImageUpload = (images) => {
        // ensure jpeg or png format show alert if not
        if (!images.every((image) => image.name.endsWith('.jpg') || image.name.endsWith('.png') || image.name.endsWith('.jpeg'))) {
            alert("Only .jpg and .png files are allowed.");
            return;
        }
        
        setFormData((prev) => ({ ...prev, images }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.modelName) newErrors.modelName = "Model name is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.material) newErrors.material = "Material is required.";
        if (!formData.brandName) newErrors.brandName = "Brand name is required.";
        if (!formData.purchaseDate) newErrors.purchaseDate = "Purchase date is required.";
        if (!formData.launchDate) newErrors.launchDate = "Launch date is required.";
        if (!formData.usage) newErrors.usage = "Usage type is required.";
        if (!formData.price) newErrors.price = "Price is required.";
        if (!formData.originalPrice) newErrors.originalPrice = "Original price is required.";
        if (!formData.purchasedFrom) newErrors.purchasedFrom = "Purchased from is required.";
        if (formData.isFirstOwner === 'no' && !formData.numberOfOwners) newErrors.numberOfOwners = "Number of owners is required.";
        if (!formData.predictionYears) newErrors.predictionYears = "Prediction years selection is required.";
        setErrors(newErrors);
        console.error("Errors:", newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        setLoading(true);
        try {
            const response = await addFurniture(formData);
            if (response && response.id) {
                console.log("Furniture added:", response);
                window.location.href = `https://furniture.cyferwall.com/furniture/${response.id}`;
            }
        } catch (error) {
            console.error("Failed to add furniture:", error);
        } finally {
            setLoading(false);
        }
    };

    const blogTheme = createTheme(getBlogTheme(mode));

    return (
        <ThemeProvider theme={blogTheme}>
            <CssBaseline enableColorScheme />
            <AppAppBar mode={mode} toggleTheme={toggleTheme} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container maxWidth="md" component="main" sx={{ mt: 25, mb: 4 }}>
                    {loading ? (
                        <LoadingScreen />
                    ) : (
                        <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" gutterBottom>
                                        Price Your Product
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Use our proprietary models to get a accurate price estimation for your product.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                        <InputLabel>Product Images (Only png and jpeg are allowed)</InputLabel>
                                    {/* add a note saying only png and jpeg are allowed */}
                                    <ImageUpload onUpload={handleImageUpload} />
                                    {/* show errors */}
                                    {errors.images && (
                                        <Typography variant="body2" color="error">
                                            {errors.images}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Model Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="modelName"
                                        value={formData.modelName}
                                        onChange={handleInputChange}
                                        error={!!errors.modelName}
                                        helperText={errors.modelName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Product Condition and Description</InputLabel>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={2}
                                        maxRows={4}
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        error={!!errors.description}
                                        helperText={errors.description}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                minHeight: '60px',
                                            },
                                        }}
                                    />
                                </Grid>
                                {/* Materials */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Material</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name="material"
                                            value={formData.material}
                                            onChange={handleInputChange}
                                            error={!!errors.material}
                                            helperText={errors.material}
                                        >
                                            {materials.map((material) => (
                                                <MenuItem key={material} value={material}>
                                                    {material}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Brand Name */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Brand Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="brandName"
                                        value={formData.brandName}
                                        onChange={handleInputChange}
                                        error={!!errors.brandName}
                                        helperText={errors.brandName}
                                    />
                                </Grid>

                                {/* Month and Year of Purchase */}
                                {/* create a datepicker */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Month and Year of Purchase</InputLabel>
                                    <DatePicker
                                        name="purchaseDate"
                                        // make full width
                                        fullWidth
                                        value={formData.purchaseDate}
                                        onChange={(date) => {
                                            handlePurchaseDateChange(date);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Month and Year of Launch Of Product</InputLabel>
                                    <DatePicker
                                        name="launchDate"
                                        value={formData.launchDate}
                                        onChange={(date) => {
                                            handleLaunchDateChange(date);
                                        }}
                                    />
                                </Grid>

                                {/* Price */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Price of Purchase (In USD $)</InputLabel>

                                    <TextField
                                        fullWidth
                                        name="price"
                                        type="number"
                                        value={formData.price}
                                        onChange={handleInputChange}
                                        error={!!errors.price}
                                        helperText={errors.price}
                                        InputLabelProps={{
                                            sx: {
                                                ...labelStyle,
                                                ...(formData.price && floatingLabelStyle) // Apply styles based on value
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Price At Launch (In USD $)</InputLabel>

                                    <TextField
                                        fullWidth
                                        name="originalPrice"
                                        type="number"
                                        value={formData.originalPrice}
                                        onChange={handleInputChange}
                                        error={!!errors.originalPrice}
                                        helperText={errors.originalPrice}
                                        InputLabelProps={{
                                            sx: {
                                                ...labelStyle,
                                                ...(formData.originalPrice && floatingLabelStyle) // Apply styles based on value
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* Usage */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>How Used</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name="usage"
                                            value={formData.usage}
                                            onChange={handleInputChange}
                                            error={!!errors.usage}
                                            helperText={errors.usage}
                                        >
                                            {usageTypes.map((usage) => (
                                                <MenuItem key={usage} value={usage}>
                                                    {usage}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>




                                {/* Purchased From */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel>Purchased From</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="purchasedFrom"
                                        value={formData.purchasedFrom}
                                        onChange={handleInputChange}
                                        error={!!errors.purchasedFrom}
                                        helperText={errors.purchasedFrom}
                                        InputLabelProps={{
                                            sx: {
                                                ...labelStyle,
                                                ...(formData.purchasedFrom && floatingLabelStyle) // Apply styles based on value
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* First Owner Selection | half width if yes, full width if no */}
                                <Grid item xs={12} sm={formData.isFirstOwner === 'yes' ? 12 : 6}>
                                    <InputLabel>Are you the first owner?</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name="isFirstOwner"
                                            value={formData.isFirstOwner}
                                            onChange={handleInputChange}
                                            error={!!errors.isFirstOwner}
                                            helperText={errors.isFirstOwner}
                                        >
                                            <MenuItem value="yes">Yes</MenuItem>
                                            <MenuItem value="no">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Number of Owners Dropdown, conditional rendering */}
                                {formData.isFirstOwner === 'no' && (
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel>Number of Owners</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name="numberOfOwners"
                                                value={formData.numberOfOwners}
                                                onChange={handleInputChange}
                                                error={!!errors.numberOfOwners}
                                                helperText={errors.numberOfOwners}
                                            >
                                                {ownerOptions.map((owner) => (
                                                    <MenuItem key={owner} value={owner}>
                                                        {owner}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    {/* add a option to select how many years into the future to predict the price, option to select 1 year, 2 year, 3 years */}
                                    <InputLabel>Predict Price In</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name="predictionYears"
                                            value={formData.predictionYears}
                                            onChange={handleInputChange}
                                            error={!!errors.predictionYears}
                                            helperText={errors.predictionYears}
                                        >
                                            {predictionOptions.map((prediction) => (
                                                <MenuItem key={prediction} value={prediction}>
                                                    {prediction}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleSubmit}
                                    >
                                        Submit Product
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Container>
            </LocalizationProvider>
            <Footer />
        </ThemeProvider>
    );
}
