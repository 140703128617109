import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFurniture } from './providers/FurnitureProvider';
import {
    CssBaseline,
    Container,
    CircularProgress,
    Typography,
    Box,
    Grid,
    Chip,
    Paper,
    Divider,
    Skeleton,
    Avatar,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import "chart.js/auto";
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MoneyOutlined, StorefrontOutlined, CalendarTodayOutlined, LayersOutlined, CheckCircleOutline, PlaceOutlined } from '@mui/icons-material';
import getBlogTheme from './theme/getBlogTheme';
import { useThemeContext } from './providers/ThemeContextProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const domain = 'https://furniture.cyferwall.com';

const FurnitureDetails = () => {
    const { id } = useParams();
    const { fetchFurnitureById, selectedFurniture, loading, error } = useFurniture();
    const { mode, toggleTheme } = useThemeContext();
    const [showCustomTheme] = React.useState(true);
    const blogTheme = createTheme(getBlogTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });

    // State to store dynamic price history
    const [priceHistory, setPriceHistory] = useState([]);

    useEffect(() => {
        fetchFurnitureById(id);
    }, [fetchFurnitureById, id]);

    useEffect(() => {
        if (selectedFurniture && selectedFurniture.predicted_price_chart) {
            // Assuming `predicted_price_chart` is an array of objects with year and price properties
            console.log(selectedFurniture.predicted_price_chart);
            setPriceHistory(selectedFurniture.predicted_price_chart);
        }
    }, [selectedFurniture]);

    const chartData = {
        labels: priceHistory.map(item => item.year),
        datasets: [{
            label: 'Price Over Time',
            data: priceHistory.map(item => item.price),
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#21CBF3',
        }],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Price (USD)',
                },
            },
        },
    };

    const getDetailValue = (value, placeholder = "Not available") => value || placeholder;

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    );

    if (error) return <Typography color="error">{error}</Typography>;

    const predictedPrice = selectedFurniture?.predicted_price;

    return (
        <ThemeProvider theme={showCustomTheme ? blogTheme : defaultTheme}>
            <CssBaseline />
            <AppAppBar mode={mode} toggleColorMode={toggleTheme} />

            <Container
                maxWidth="lg"
                component="main"
                sx={{ display: 'flex', flexDirection: 'column', my: 23, gap: 4 }}
            >
                <Grid container spacing={4}>
                    {/* Left Column - Image */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={4} sx={{ borderRadius: 3, overflow: 'hidden', boxShadow: '0px 5px 15px rgba(0,0,0,0.2)' }}>
                            <Slider {...settings}>
                                {selectedFurniture?.images.map((imgPath, imgIndex) => (
                                    <Box key={imgIndex}>
                                        <img
                                            src={domain + imgPath}
                                            alt={selectedFurniture.model_name}
                                            style={{
                                                width: '100%',
                                                height: '400px',
                                                objectFit: 'cover', // This maintains the aspect ratio and fills the area
                                                objectPosition: 'center', // Center the image
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Slider>
                        </Paper>
                    </Grid>

                    {/* Right Column - Details */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ height: '100%' }}>
                            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>
                                {getDetailValue(selectedFurniture?.model_name)}
                            </Typography>

                            {/* Predicted Price Card */}
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 3,
                                    mb: 3,
                                    background: 'linear-gradient(135deg, #0288d1 30%, #26c6da 90%)',
                                    color: '#fafafa',
                                    borderRadius: 3,
                                    textAlign: 'center',
                                    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
                                    transition: 'transform 0.3s ease-in-out',
                                    ':hover': { transform: 'scale(1.05)' }
                                }}
                            >
                                <Typography variant="h6">Predicted Price</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                    <MoneyOutlined />
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>
                                        ${predictedPrice}
                                    </Typography>
                                </Box>
                            </Paper>

                            <Typography variant="body1" paragraph>
                                {getDetailValue(selectedFurniture?.description)}
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Details Grid */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Furniture Details
                            </Typography>
                            <Divider sx={{ mb: 3 }} />
                            <Grid container spacing={3}>
                                {[
                                    { label: 'Brand', value: selectedFurniture?.brand_name, icon: <StorefrontOutlined /> },
                                    { label: 'Materials', value: selectedFurniture?.materials, icon: <LayersOutlined /> },
                                    { label: 'Condition', value: selectedFurniture?.how_used, isChip: true, icon: <CheckCircleOutline /> },
                                    { label: 'First Owner', value: selectedFurniture?.first_owner === true ? 'Yes' : 'No', icon: <CheckCircleOutline /> },
                                    { label: 'Years of Ownership', value: selectedFurniture?.years_of_ownership, icon: <CalendarTodayOutlined /> },
                                    { label: 'Purchased From', value: selectedFurniture?.purchased_from, icon: <PlaceOutlined /> },
                                    {
                                        label: 'Purchase Date',
                                        value: `${selectedFurniture?.purchase_date}`,
                                        icon: <CalendarTodayOutlined />
                                    },
                                    { label: 'Price of Purchase', value: `$${selectedFurniture?.price_of_purchase}`, icon: <MoneyOutlined /> }
                                ].map((detail, index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {detail.icon && (
                                                <Avatar variant="rounded" sx={{ backgroundColor: '#e0f7fa', color: '#0288d1', width: 24, height: 24 }}>
                                                    {detail.icon}
                                                </Avatar>
                                            )}
                                            <Box>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    {detail.label}
                                                </Typography>
                                                {detail.isChip ? (
                                                    <Chip
                                                        label={getDetailValue(detail.value)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ borderRadius: 2 }}
                                                    />
                                                ) : (
                                                    <Typography variant="body1">{getDetailValue(detail.value)}</Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Price History Chart */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Price History
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Box sx={{ height: 300 }}>
                                <Line data={chartData} options={options} />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </ThemeProvider>
    );
};

export default FurnitureDetails;
